/**
 * File generated by js-routes 2.2.0
 * Based on Rails 6.1.4.4 routes of Securus::Application
 */
const __jsr = ((that) => {
  const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
  let NodeTypes;
  (function (NodeTypes) {
    NodeTypes[(NodeTypes['GROUP'] = 1)] = 'GROUP';
    NodeTypes[(NodeTypes['CAT'] = 2)] = 'CAT';
    NodeTypes[(NodeTypes['SYMBOL'] = 3)] = 'SYMBOL';
    NodeTypes[(NodeTypes['OR'] = 4)] = 'OR';
    NodeTypes[(NodeTypes['STAR'] = 5)] = 'STAR';
    NodeTypes[(NodeTypes['LITERAL'] = 6)] = 'LITERAL';
    NodeTypes[(NodeTypes['SLASH'] = 7)] = 'SLASH';
    NodeTypes[(NodeTypes['DOT'] = 8)] = 'DOT';
  })(NodeTypes || (NodeTypes = {}));
  const Root = that;
  const isBroswer = typeof window !== 'undefined';
  const ModuleReferences = {
    CJS: {
      define(routes) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        module.exports = routes;
      },
      isSupported() {
        return typeof module === 'object';
      },
    },
    AMD: {
      define(routes) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        define([], function () {
          return routes;
        });
      },
      isSupported() {
        return typeof define === 'function' && !!define.amd;
      },
    },
    UMD: {
      define(routes) {
        if (ModuleReferences.AMD.isSupported()) {
          ModuleReferences.AMD.define(routes);
        } else {
          if (ModuleReferences.CJS.isSupported()) {
            try {
              ModuleReferences.CJS.define(routes);
            } catch (error) {
              if (error.name !== 'TypeError') throw error;
            }
          }
        }
      },
      isSupported() {
        return ModuleReferences.AMD.isSupported() || ModuleReferences.CJS.isSupported();
      },
    },
    ESM: {
      define() {
        // Module can only be defined using ruby code generation
      },
      isSupported() {
        // Its impossible to check if "export" keyword is supported
        return true;
      },
    },
    NIL: {
      define(routes) {
        Utils.namespace(Root, null, routes);
      },
      isSupported() {
        return !null || !!Root;
      },
    },
    DTS: {
      // Acts the same as ESM
      define(routes) {
        ModuleReferences.ESM.define(routes);
      },
      isSupported() {
        return ModuleReferences.ESM.isSupported();
      },
    },
  };
  class ParametersMissing extends Error {
    constructor(...keys) {
      super(`Route missing required keys: ${keys.join(', ')}`);
      this.keys = keys;
      Object.setPrototypeOf(this, Object.getPrototypeOf(this));
      this.name = ParametersMissing.name;
    }
  }
  const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
  const ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];
  class UtilsClass {
    constructor() {
      this.configuration = {
        prefix: '',
        default_url_options: {},
        special_options_key: '_options',
        serializer: null || this.default_serializer.bind(this),
      };
    }
    default_serializer(value, prefix) {
      if (this.is_nullable(value)) {
        return '';
      }
      if (!prefix && !this.is_object(value)) {
        throw new Error('Url parameters should be a javascript hash');
      }
      prefix = prefix || '';
      const result = [];
      if (this.is_array(value)) {
        for (const element of value) {
          result.push(this.default_serializer(element, prefix + '[]'));
        }
      } else if (this.is_object(value)) {
        for (let key in value) {
          if (!hasProp(value, key)) continue;
          let prop = value[key];
          if (this.is_nullable(prop) && prefix) {
            prop = '';
          }
          if (this.is_not_nullable(prop)) {
            if (prefix) {
              key = prefix + '[' + key + ']';
            }
            result.push(this.default_serializer(prop, key));
          }
        }
      } else {
        if (this.is_not_nullable(value)) {
          result.push(encodeURIComponent(prefix) + '=' + encodeURIComponent('' + value));
        }
      }
      return result.join('&');
    }
    serialize(object) {
      return this.configuration.serializer(object);
    }
    extract_options(number_of_params, args) {
      const last_el = args[args.length - 1];
      if (
        (args.length > number_of_params && last_el === 0) ||
        (this.is_object(last_el) && !this.looks_like_serialized_model(last_el))
      ) {
        if (this.is_object(last_el)) {
          delete last_el[this.configuration.special_options_key];
        }
        return {
          args: args.slice(0, args.length - 1),
          options: last_el,
        };
      } else {
        return { args, options: {} };
      }
    }
    looks_like_serialized_model(object) {
      return (
        this.is_object(object) &&
        !(this.configuration.special_options_key in object) &&
        ('id' in object || 'to_param' in object || 'toParam' in object)
      );
    }
    path_identifier(object) {
      const result = this.unwrap_path_identifier(object);
      return this.is_nullable(result) || result === false ? '' : '' + result;
    }
    unwrap_path_identifier(object) {
      let result = object;
      if (!this.is_object(object)) {
        return object;
      }
      if ('to_param' in object) {
        result = object.to_param;
      } else if ('toParam' in object) {
        result = object.toParam;
      } else if ('id' in object) {
        result = object.id;
      } else {
        result = object;
      }
      return this.is_callable(result) ? result.call(object) : result;
    }
    partition_parameters(parts, required_params, default_options, call_arguments) {
      // eslint-disable-next-line prefer-const
      let { args, options } = this.extract_options(parts.length, call_arguments);
      if (args.length > parts.length) {
        throw new Error('Too many parameters provided for path');
      }
      let use_all_parts = args.length > required_params.length;
      const parts_options = {
        ...this.configuration.default_url_options,
      };
      for (const key in options) {
        const value = options[key];
        if (!hasProp(options, key)) continue;
        use_all_parts = true;
        if (parts.includes(key)) {
          parts_options[key] = value;
        }
      }
      options = {
        ...this.configuration.default_url_options,
        ...default_options,
        ...options,
      };
      const keyword_parameters = {};
      const query_parameters = {};
      for (const key in options) {
        if (!hasProp(options, key)) continue;
        const value = options[key];
        if (this.is_reserved_option(key)) {
          keyword_parameters[key] = value;
        } else {
          if (!this.is_nullable(value) && (value !== default_options[key] || required_params.includes(key))) {
            query_parameters[key] = value;
          }
        }
      }
      const route_parts = use_all_parts ? parts : required_params;
      let i = 0;
      for (const part of route_parts) {
        if (i < args.length) {
          const value = args[i];
          if (!hasProp(parts_options, part)) {
            query_parameters[part] = value;
            ++i;
          }
        }
      }
      return { keyword_parameters, query_parameters };
    }
    build_route(parts, required_params, default_options, route, absolute, args) {
      const { keyword_parameters, query_parameters } = this.partition_parameters(
        parts,
        required_params,
        default_options,
        args
      );
      const missing_params = required_params.filter(
        (param) => !hasProp(query_parameters, param) || this.is_nullable(query_parameters[param])
      );
      if (missing_params.length) {
        throw new ParametersMissing(...missing_params);
      }
      let result = this.get_prefix() + this.visit(route, query_parameters);
      if (keyword_parameters.trailing_slash) {
        result = result.replace(/(.*?)[/]?$/, '$1/');
      }
      const url_params = this.serialize(query_parameters);
      if (url_params.length) {
        result += '?' + url_params;
      }
      result += keyword_parameters.anchor ? '#' + keyword_parameters.anchor : '';
      if (absolute) {
        result = this.route_url(keyword_parameters) + result;
      }
      return result;
    }
    visit(route, parameters, optional = false) {
      switch (route[0]) {
        case NodeTypes.GROUP:
          return this.visit(route[1], parameters, true);
        case NodeTypes.CAT:
          return this.visit_cat(route, parameters, optional);
        case NodeTypes.SYMBOL:
          return this.visit_symbol(route, parameters, optional);
        case NodeTypes.STAR:
          return this.visit_globbing(route[1], parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return route[1];
        default:
          throw new Error('Unknown Rails node type');
      }
    }
    is_not_nullable(object) {
      return !this.is_nullable(object);
    }
    is_nullable(object) {
      return object === undefined || object === null;
    }
    visit_cat(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [_type, left, right],
      parameters,
      optional
    ) {
      const left_part = this.visit(left, parameters, optional);
      let right_part = this.visit(right, parameters, optional);
      if (
        optional &&
        ((this.is_optional_node(left[0]) && !left_part) || (this.is_optional_node(right[0]) && !right_part))
      ) {
        return '';
      }
      // if left_part ends on '/' and right_part starts on '/'
      if (left_part[left_part.length - 1] === '/' && right_part[0] === '/') {
        // strip slash from right_part
        // to prevent double slash
        right_part = right_part.substring(1);
      }
      return left_part + right_part;
    }
    visit_symbol(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [_type, key],
      parameters,
      optional
    ) {
      const value = this.path_identifier(parameters[key]);
      delete parameters[key];
      if (value.length) {
        return this.encode_segment(value);
      }
      if (optional) {
        return '';
      } else {
        throw new ParametersMissing(key);
      }
    }
    encode_segment(segment) {
      return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
    }
    is_optional_node(node) {
      return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
    }
    build_path_spec(route, wildcard = false) {
      let key;
      switch (route[0]) {
        case NodeTypes.GROUP:
          return '(' + this.build_path_spec(route[1]) + ')';
        case NodeTypes.CAT:
          return this.build_path_spec(route[1]) + this.build_path_spec(route[2]);
        case NodeTypes.STAR:
          return this.build_path_spec(route[1], true);
        case NodeTypes.SYMBOL:
          key = route[1];
          if (wildcard) {
            return (key.startsWith('*') ? '' : '*') + key;
          } else {
            return ':' + key;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return route[1];
        default:
          throw new Error('Unknown Rails node type');
      }
    }
    visit_globbing(route, parameters, optional) {
      const key = route[1];
      let value = parameters[key];
      delete parameters[key];
      if (this.is_nullable(value)) {
        return this.visit(route, parameters, optional);
      }
      if (this.is_array(value)) {
        value = value.join('/');
      }
      const result = this.path_identifier(value);
      return false ? result : encodeURI(result);
    }
    get_prefix() {
      const prefix = this.configuration.prefix;
      return prefix.match('/$') ? prefix.substring(0, prefix.length - 1) : prefix;
    }
    route(parts_table, route_spec, absolute = false) {
      const required_params = [];
      const parts = [];
      const default_options = {};
      for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
        parts.push(part);
        if (required) {
          required_params.push(part);
        }
        if (this.is_not_nullable(value)) {
          default_options[part] = value;
        }
      }
      const result = (...args) => {
        return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
      };
      result.requiredParams = () => required_params;
      result.toString = () => {
        return this.build_path_spec(route_spec);
      };
      return result;
    }
    route_url(route_defaults) {
      const hostname = route_defaults.host || this.current_host();
      if (!hostname) {
        return '';
      }
      const subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      const protocol = route_defaults.protocol || this.current_protocol();
      let port = route_defaults.port || (!route_defaults.host ? this.current_port() : undefined);
      port = port ? ':' + port : '';
      return protocol + '://' + subdomain + hostname + port;
    }
    current_host() {
      var _a;
      return (
        (isBroswer &&
          ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
            ? void 0
            : _a.hostname)) ||
        ''
      );
    }
    current_protocol() {
      var _a, _b;
      return (
        (isBroswer &&
          ((_b =
            (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
              ? void 0
              : _a.protocol) === null || _b === void 0
            ? void 0
            : _b.replace(/:$/, ''))) ||
        'http'
      );
    }
    current_port() {
      var _a;
      return (
        (isBroswer &&
          ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
            ? void 0
            : _a.port)) ||
        ''
      );
    }
    is_object(value) {
      return typeof value === 'object' && Object.prototype.toString.call(value) === '[object Object]';
    }
    is_array(object) {
      return object instanceof Array;
    }
    is_callable(object) {
      return typeof object === 'function' && !!object.call;
    }
    is_reserved_option(key) {
      return ReservedOptions.includes(key);
    }
    namespace(object, namespace, routes) {
      const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split('.')) || [];
      if (parts.length === 0) {
        return;
      }
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];
        if (index < parts.length - 1) {
          object = object[part] || (object[part] = {});
        } else {
          object[part] = routes;
        }
      }
    }
    configure(new_config) {
      this.configuration = { ...this.configuration, ...new_config };
      return this.configuration;
    }
    config() {
      return { ...this.configuration };
    }
    is_module_supported(name) {
      return ModuleReferences[name].isSupported();
    }
    ensure_module_supported(name) {
      if (!this.is_module_supported(name)) {
        throw new Error(`${name} is not supported by runtime`);
      }
    }
    define_module(name, module) {
      this.ensure_module_supported(name);
      ModuleReferences[name].define(module);
    }
  }
  const Utils = new UtilsClass();
  // We want this helper name to be short
  const __jsr = {
    r(parts_table, route_spec, absolute) {
      return Utils.route(parts_table, route_spec, absolute);
    },
  };
  const result = {
    ...__jsr,
    configure: (config) => {
      return Utils.configure(config);
    },
    config: () => {
      return Utils.config();
    },
    serialize: (object) => {
      return Utils.serialize(object);
    },
    ...{},
  };
  Utils.define_module('ESM', result);
  return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /api/v1/alarms/:alarm_id/alarm_comments(.:format)
 * @param {any} alarm_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarm_comments_api_v1_alarm_path = __jsr.r({ alarm_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'alarms'],
            [
              2,
              [7, '/'],
              [2, [3, 'alarm_id'], [2, [7, '/'], [2, [6, 'alarm_comments'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/triggers/:trigger_id/alarm_info(.:format)
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarm_info_api_v1_trigger_path = __jsr.r({ trigger_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'triggers'],
            [
              2,
              [7, '/'],
              [2, [3, 'trigger_id'], [2, [7, '/'], [2, [6, 'alarm_info'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/alarm_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarm_logs_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'alarm_logs'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:device_id/metrics/:metric_id/alarm_query(.:format)
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarm_query_api_v1_device_metric_path = __jsr.r(
  { device_id: { r: true }, metric_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'devices'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'device_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'metrics'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'metric_id'],
                          [2, [7, '/'], [2, [6, 'alarm_query'], [1, [2, [8, '.'], [3, 'format']]]]],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /alarms/alarm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarms_alarm_info_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'alarms'], [2, [7, '/'], [2, [6, 'alarm_info'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /alarms/mute_alarm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alarms_mute_alarm_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'alarms'], [2, [7, '/'], [2, [6, 'mute_alarm'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/export_alarm_config(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_export_alarm_config_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'export_alarm_config'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/export_device_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_export_device_settings_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'export_device_settings'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/import_alarm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_import_alarm_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'import_alarm'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/import_branch_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_import_branch_version_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'import_branch_version'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/import_data_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_import_data_report_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'import_data_report'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/import_firmware(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_import_firmware_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'import_firmware'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/init_shl_gateway(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_init_shl_gateway_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'init_shl_gateway'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/gateway/refresh_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_gateway_refresh_data_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'gateway'], [2, [7, '/'], [2, [6, 'refresh_data'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/import_shl_metrics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_import_shl_metrics_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'import_shl_metrics'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/setup/assigned_device_to_site(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_setup_assigned_device_to_site_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'setup'], [2, [7, '/'], [2, [6, 'assigned_device_to_site'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/setup/assigned_device_to_tenant(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_setup_assigned_device_to_tenant_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'setup'], [2, [7, '/'], [2, [6, 'assigned_device_to_tenant'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/setup/first_setup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_setup_first_setup_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'setup'], [2, [7, '/'], [2, [6, 'first_setup'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /assign_tenant/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'assign_tenant'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/:trigger_id/change_condition_view(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_condition_view_tenant_customer_trigger_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    trigger_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [
                                        2,
                                        [7, '/'],
                                        [
                                          2,
                                          [3, 'trigger_id'],
                                          [
                                            2,
                                            [7, '/'],
                                            [2, [6, 'change_condition_view'], [1, [2, [8, '.'], [3, 'format']]]],
                                          ],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/triggers/:trigger_id/check_active_alarm(.:format)
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_active_alarm_api_v1_trigger_path = __jsr.r({ trigger_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'triggers'],
            [
              2,
              [7, '/'],
              [2, [3, 'trigger_id'], [2, [7, '/'], [2, [6, 'check_active_alarm'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/customers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'customers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:id/dashboard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:id/dashboard(.:format)
 * @param {any} tenant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_tenant_customer_path = __jsr.r({ tenant_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'customers'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/dashboard(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/dashboard(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_tenant_customer_site_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/devices/:id/data_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_logs_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'data_logs'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/data_logs_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_logs_info_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'data_logs_info'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/delete_old_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_old_data_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'delete_old_data'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'sign_out'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/device_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const device_info_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'device_info'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/device_metrics_sensors(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const device_metrics_sensors_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'device_metrics_sensors'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/devices(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const devices_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'devices'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/tags/:tag_id/devices(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} tag_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const devices_tenant_customer_tag_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, tag_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'tags'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'tag_id'],
                                  [2, [7, '/'], [2, [6, 'devices'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'inbound_emails'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:id/general(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:id/general(.:format)
 * @param {any} tenant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_path = __jsr.r({ tenant_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'customers'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/metrics/:metric_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_metric_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    metric_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'metrics'],
                                      [
                                        2,
                                        [7, '/'],
                                        [
                                          2,
                                          [3, 'metric_id'],
                                          [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/notification_groups/:notification_group_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} notification_group_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_notification_group_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    notification_group_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'notification_groups'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'notification_group_id'],
                                  [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_site_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/tags/:tag_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} tag_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_tag_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, tag_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'tags'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'tag_id'],
                                  [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/:trigger_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_trigger_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    trigger_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [
                                        2,
                                        [7, '/'],
                                        [
                                          2,
                                          [3, 'trigger_id'],
                                          [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/:user_id/general(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'users'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'user_id'],
                                  [2, [7, '/'], [2, [6, 'general'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /letter_opener
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_path = __jsr.r({}, [2, [7, '/'], [6, 'letter_opener']]);

/**
 * Generates rails route to
 * /letter_opener/clear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_clear_letters_path = __jsr.r({ format: {} }, [
  2,
  [2, [2, [7, '/'], [6, 'letter_opener']], [7, '/']],
  [2, [6, 'clear'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /letter_opener/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_delete_letter_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [2, [2, [7, '/'], [6, 'letter_opener']], [7, '/']],
  [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /letter_opener/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_letters_path = __jsr.r({}, [2, [2, [7, '/'], [6, 'letter_opener']], [7, '/']]);

/**
 * Generates rails route to
 * /letter_opener/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_letter_path = __jsr.r({ id: { r: true }, style: {}, format: {} }, [
  2,
  [2, [2, [7, '/'], [6, 'letter_opener']], [7, '/']],
  [2, [3, 'id'], [2, [1, [2, [7, '/'], [3, 'style']]], [1, [2, [8, '.'], [3, 'format']]]]],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logs_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'logs'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/logs(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logs_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'logs'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/mark_alarms_as_seen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_alarms_as_seen_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'mark_alarms_as_seen'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/metric_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metric_data_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'metric_data'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/metric_data_weekly(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metric_data_weekly_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'metric_data_weekly'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/metric_last_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metric_last_data_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'metric_last_data'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:device_id/metrics/:metric_id/metric_query(.:format)
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metric_query_api_v1_device_metric_path = __jsr.r(
  { device_id: { r: true }, metric_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'devices'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'device_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'metrics'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'metric_id'],
                          [2, [7, '/'], [2, [6, 'metric_query'], [1, [2, [8, '.'], [3, 'format']]]]],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/devices/:id/metrics_status_query(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metrics_status_query_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'metrics_status_query'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/alarms/:alarm_id/missed_alarms_add_comment(.:format)
 * @param {any} alarm_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missed_alarms_add_comment_api_v1_alarm_path = __jsr.r({ alarm_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'alarms'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'alarm_id'],
                [2, [7, '/'], [2, [6, 'missed_alarms_add_comment'], [1, [2, [8, '.'], [3, 'format']]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/missed_alarms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missed_alarms_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'missed_alarms'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/missed_alarms_button(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missed_alarms_button_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'missed_alarms_button'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/alarms/:alarm_id/missed_alarms_get_comments(.:format)
 * @param {any} alarm_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missed_alarms_get_comments_api_v1_alarm_path = __jsr.r({ alarm_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'alarms'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'alarm_id'],
                [2, [7, '/'], [2, [6, 'missed_alarms_get_comments'], [1, [2, [8, '.'], [3, 'format']]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /my_hidden_test_exception(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_hidden_test_exception_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'my_hidden_test_exception'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'inbound_emails'],
                [2, [7, '/'], [2, [6, 'sources'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'tenants'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/new(.:format)
 * @param {any} tenant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_path = __jsr.r({ tenant_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [2, [7, '/'], [2, [6, 'customers'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [2, [6, 'devices'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/metrics/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_metric_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'metrics'],
                                      [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/notification_groups/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_notification_group_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'notification_groups'],
                              [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_site_path = __jsr.r({ tenant_id: { r: true }, customer_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'customers'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'customer_id'],
                [2, [7, '/'], [2, [6, 'sites'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/tags/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_tag_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [2, [6, 'tags'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_trigger_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/new(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [2, [6, 'users'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [2, [7, '/'], [2, [6, 'confirmation'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/:trigger_id/notification_groups(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_groups_tenant_customer_trigger_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    trigger_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [
                                        2,
                                        [7, '/'],
                                        [
                                          2,
                                          [3, 'trigger_id'],
                                          [
                                            2,
                                            [7, '/'],
                                            [2, [6, 'notification_groups'], [1, [2, [8, '.'], [3, 'format']]]],
                                          ],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/overview_alarm_count(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overview_alarm_count_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'overview_alarm_count'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/:user_id/profile(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'users'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'user_id'],
                                  [2, [7, '/'], [2, [6, 'profile'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/triggers/:trigger_id/quit_alarm(.:format)
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quit_alarm_api_v1_trigger_path = __jsr.r({ trigger_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'triggers'],
            [
              2,
              [7, '/'],
              [2, [3, 'trigger_id'], [2, [7, '/'], [2, [6, 'quit_alarm'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r(
  { signed_blob_id: { r: true }, variation_key: { r: true }, filename: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'rails'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'active_storage'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'representations'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'redirect'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'signed_blob_id'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'variation_key'],
                          [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r(
  { signed_blob_id: { r: true }, variation_key: { r: true }, filename: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'rails'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'active_storage'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'representations'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'proxy'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'signed_blob_id'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'variation_key'],
                          [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [2, [7, '/'], [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({ inbound_email_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [2, [3, 'inbound_email_id'], [2, [7, '/'], [2, [6, 'reroute'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [6, 'sources'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [2, [6, 'action_mailbox'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [2, [6, 'active_storage'], [2, [7, '/'], [2, [6, 'direct_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({ encoded_key: { r: true }, filename: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'disk'],
            [
              2,
              [7, '/'],
              [2, [3, 'encoded_key'], [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'rails'], [2, [7, '/'], [2, [6, 'info'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [2, [7, '/'], [2, [6, 'info'], [2, [7, '/'], [2, [6, 'properties'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [2, [7, '/'], [2, [6, 'info'], [2, [7, '/'], [2, [6, 'routes'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'rails'], [2, [7, '/'], [2, [6, 'mailers'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'mailgun'],
            [
              2,
              [7, '/'],
              [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [6, 'mime'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'mandrill'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'mandrill'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'postmark'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [2, [7, '/'], [2, [6, 'relay'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'sendgrid'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({ signed_id: { r: true }, filename: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'blobs'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'redirect'],
                [
                  2,
                  [7, '/'],
                  [2, [3, 'signed_id'], [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({ signed_id: { r: true }, filename: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'blobs'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'proxy'],
                [
                  2,
                  [7, '/'],
                  [2, [3, 'signed_id'], [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/refresh_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_data_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'refresh_data'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:device_id/metrics/:metric_id/relay_off(.:format)
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const relay_off_api_v1_device_metric_path = __jsr.r(
  { device_id: { r: true }, metric_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'devices'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'device_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'metrics'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'metric_id'], [2, [7, '/'], [2, [6, 'relay_off'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/devices/:device_id/metrics/:metric_id/relay_on(.:format)
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const relay_on_api_v1_device_metric_path = __jsr.r(
  { device_id: { r: true }, metric_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'devices'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'device_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'metrics'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'metric_id'], [2, [7, '/'], [2, [6, 'relay_on'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/devices/:id/restart_device(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restart_device_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'restart_device'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/restore(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'restore'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7, '/']);

/**
 * Generates rails route to
 * /api/v1/devices/:id/send_alarm_config(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_alarm_config_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'send_alarm_config'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/send_branch_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_branch_version_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'send_branch_version'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/send_data_now(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_data_now_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'send_data_now'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/send_firmware(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_firmware_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'send_firmware'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/settings(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'settings'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/settings(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_tenant_customer_site_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'settings'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/:trigger_id/show_clear_timer(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_clear_timer_tenant_customer_trigger_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    trigger_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [
                                        2,
                                        [7, '/'],
                                        [
                                          2,
                                          [3, 'trigger_id'],
                                          [
                                            2,
                                            [7, '/'],
                                            [2, [6, 'show_clear_timer'], [1, [2, [8, '.'], [3, 'format']]]],
                                          ],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/history_entry/:id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_history_entry_tenant_customer_device_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'history_entry'],
                                      [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/show_mute_alarm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_mute_alarm_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [
              2,
              [7, '/'],
              [2, [3, 'id'], [2, [7, '/'], [2, [6, 'show_mute_alarm'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2, [7, '/'], [6, 'sidekiq']]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/sites(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'sites'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/:user_id/sites(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'users'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'user_id'],
                                  [2, [7, '/'], [2, [6, 'sites'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'tenants'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/assign_site/:id(.:format)
 * @param {any} tenant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_assign_site_path = __jsr.r({ tenant_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [2, [7, '/'], [2, [6, 'assign_site'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:id(.:format)
 * @param {any} tenant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_path = __jsr.r({ tenant_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [2, [7, '/'], [2, [6, 'customers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_device_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [2, [7, '/'], [2, [3, 'device_id'], [1, [2, [8, '.'], [3, 'format']]]]],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_devices_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'devices'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/metrics/:metric_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} metric_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_metric_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    metric_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'metrics'],
                                      [2, [7, '/'], [2, [3, 'metric_id'], [1, [2, [8, '.'], [3, 'format']]]]],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/metrics(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_metrics_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'metrics'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/notification_groups/:notification_group_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} notification_group_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_notification_group_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    notification_group_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'notification_groups'],
                              [2, [7, '/'], [2, [3, 'notification_group_id'], [1, [2, [8, '.'], [3, 'format']]]]],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/notification_groups(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_notification_groups_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [2, [7, '/'], [2, [6, 'notification_groups'], [1, [2, [8, '.'], [3, 'format']]]]],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_site_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [2, [6, 'sites'], [2, [7, '/'], [2, [3, 'site_id'], [1, [2, [8, '.'], [3, 'format']]]]]],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_sites_path = __jsr.r({ tenant_id: { r: true }, customer_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'customers'],
            [2, [7, '/'], [2, [3, 'customer_id'], [2, [7, '/'], [2, [6, 'sites'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/tags/:tag_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} tag_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_tag_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, tag_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [2, [6, 'tags'], [2, [7, '/'], [2, [3, 'tag_id'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/tags(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_tags_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'tags'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers/:trigger_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {any} trigger_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_trigger_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    device_id: { r: true },
    trigger_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [
                                    2,
                                    [7, '/'],
                                    [
                                      2,
                                      [6, 'triggers'],
                                      [2, [7, '/'], [2, [3, 'trigger_id'], [1, [2, [8, '.'], [3, 'format']]]]],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/devices/:device_id/triggers(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} device_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_triggers_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, device_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'devices'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'device_id'],
                                  [2, [7, '/'], [2, [6, 'triggers'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/:user_id(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [2, [6, 'users'], [2, [7, '/'], [2, [3, 'user_id'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customer_users_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [2, [3, 'site_id'], [2, [7, '/'], [2, [6, 'users'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers(.:format)
 * @param {any} tenant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_customers_path = __jsr.r({ tenant_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [2, [7, '/'], [2, [3, 'tenant_id'], [2, [7, '/'], [2, [6, 'customers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/registered_devices(.:format)
 * @param {any} tenant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_registered_devices_path = __jsr.r({ tenant_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [2, [3, 'tenant_id'], [2, [7, '/'], [2, [6, 'registered_devices'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/assign_site/:id(.:format)
 * @param {any} tenant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenant_update_site_path = __jsr.r({ tenant_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'tenants'],
    [
      2,
      [7, '/'],
      [
        2,
        [3, 'tenant_id'],
        [2, [7, '/'], [2, [6, 'assign_site'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tenants_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'tenants'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /api/v1/tenants/:id/trigger_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trigger_info_api_v1_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tenants'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'trigger_info'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /unregistered_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unregistered_devices_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'unregistered_devices'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /api/v1/devices/:id/update_agent(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_agent_api_v1_device_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'devices'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'update_agent'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/users/:user_id/update_profile(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_profile_tenant_customer_user_path = __jsr.r(
  { tenant_id: { r: true }, customer_id: { r: true }, site_id: { r: true }, user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'users'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'user_id'],
                                  [2, [7, '/'], [2, [6, 'update_profile'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({ encoded_token: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [2, [7, '/'], [2, [6, 'disk'], [2, [7, '/'], [2, [3, 'encoded_token'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /assign_tenant/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'assign_tenant'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'confirmation'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'password'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /tenants/:tenant_id/customers/:customer_id/sites/:site_id/notification_groups/:notification_group_id/users(.:format)
 * @param {any} tenant_id
 * @param {any} customer_id
 * @param {any} site_id
 * @param {any} notification_group_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_tenant_customer_notification_group_path = __jsr.r(
  {
    tenant_id: { r: true },
    customer_id: { r: true },
    site_id: { r: true },
    notification_group_id: { r: true },
    format: {},
  },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'tenants'],
      [
        2,
        [7, '/'],
        [
          2,
          [3, 'tenant_id'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'customers'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'customer_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [6, 'sites'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [3, 'site_id'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [6, 'notification_groups'],
                              [
                                2,
                                [7, '/'],
                                [
                                  2,
                                  [3, 'notification_group_id'],
                                  [2, [7, '/'], [2, [6, 'users'], [1, [2, [8, '.'], [3, 'format']]]]],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ]
);
