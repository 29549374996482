// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

// Loading bootstrap and other style librarys
import 'bootstrap';
import * as Routes from '../routes.js';
import ApexCharts from 'apexcharts';
import '../helpers/general';
import '../helpers/selects';

// I18n Translations
import I18n from 'i18n-js';
import axios from 'axios';
window.I18n = I18n;

// Alerts
window.Swal = require('sweetalert2');

// Import javascript routes
window.Routes = Routes;

// Beatuiful alerts
window.Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

// quit alarm
$(document).on('click', '#quit_trigger', function (e) {
  e.stopImmediatePropagation();
  var trigger_id = $('#trigger_name').attr('value');
  $.ajax({
    url: Routes.quit_alarm_api_v1_trigger_path(trigger_id),
    type: 'PATCH',
    complete: function (response) {
      console.log('======>', response);
      location.reload();
    },
    error: function (xhr, textStatus, errorThrown) {},
  });
});

// Apexcharts
window.ApexCharts = ApexCharts;

// Default rails stuff
Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

// Opens a modal and preventing outside click and "ESC" from closing a modal window
$(document).on('turbolinks:load', function () {
  // Active tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Custom javascript
  // Modal
  $('#modal-window').modal({
    backdrop: 'static',
    keyboard: false,
  });
  $('#modal-window').modal('show');

  // sticky selection changer
  // This is for the breadcrumbs, so that the script knows which dropdown selection has changed
  $(document).on('change', '#subitem_select_1', () => {
    let redirect_url = $('#subitem_select_1 option:selected').val();
    if (redirect_url) {
      window.location.href = redirect_url;
    }
  });

  $(document).on('change', '#subitem_select_2', () => {
    let redirect_url = $('#subitem_select_2 option:selected').val();
    if (redirect_url) {
      window.location.href = redirect_url;
    }
  });

  $(document).on('change', '#subitem_select_3', () => {
    let redirect_url = $('#subitem_select_3 option:selected').val();
    if (redirect_url) {
      window.location.href = redirect_url;
    }
  });

  $(document).on('change', '#subitem_select_4', () => {
    let redirect_url = $('#subitem_select_4 option:selected').val();
    if (redirect_url) {
      window.location.href = redirect_url;
    }
  });

  $(document).on('change', '#subitem_select_5', () => {
    let redirect_url = $('#subitem_select_5 option:selected').val();
    if (redirect_url) {
      window.location.href = redirect_url;
    }
  });
  // History log revisions
  $(document).on('click', '#revisions-toggle', (e) => {
    e.preventDefault();
    $('#revisions').toggle();
    $('#revisions-caret-right').toggle();
    $('#revisions-caret-down').toggle();
  });

  // AccountSelector
  setTimeout(() => {
    $('a.dropdown-toggle').on('click', function (e) {
      e.preventDefault();
      $('.dropdown-submenu .show').removeClass('show');
      $('.dropdown-submenu-custom .show').removeClass('show');
      var $subMenu = $(this).next('.dropdown-menu');
      $subMenu.toggleClass('show');

      $(this)
        .parents('li.nav-item.dropdown.show')
        .on('hidden.bs.dropdown', function (e) {
          $('.dropdown-submenu .show').removeClass('show');
          $('.dropdown-submenu-custom .show').removeClass('show');
        });

      return false;
    });
  }, 1);
});
