import React, { useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';

const LoadingButton = ({ path, button_text }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    axios.get(path).then((response) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };
  return (
    <button className="btn" onClick={() => handleClick()} disabled={isLoading}>
      {isLoading ? (
        <span>
          <span className="spinner-border spinner-border-sm"></span> Loading...
        </span>
      ) : (
        button_text
      )}
    </button>
  );
};

export default LoadingButton;
